export function isEmpty(string) {
    return string === ''
}

export function isNotEmpty(string) {
    return string != null && string !== ''
}

export function toPath(string = '', separator = '.') {
    const type = typeof string

    if (type === 'number') {
        return [string]
    }

    if (type !== 'string') {
        throw new TypeError(`"toPath" expect "string", got ${type}`)
    }

    return String(string).split(separator).filter(Boolean).map(toPart)
}

export function fromPath(path = [], separator = '.') {
    if (!Array.isArray(path)) {
        throw new TypeError(`"fromPath" only accepts Array, got ${typeof path}`)
    }

    return path.filter(isNotEmpty).join(separator)
}

export function initials(name) {
    if (!name || typeof name !== 'string') {
        return
    }

    const [first = '', second = ''] = name.toUpperCase().split(' ')

    return first.charAt(0) + second.charAt(0)
}

// Utils
function toPart(part) {
    const number = Number(part)

    return isNaN(number) ? part : number
}
