import Interceptor from './Interceptor'

export default class TokenInterceptor extends Interceptor {
    constructor(getToken) {
        super()

        if (typeof getToken === 'function') {
            this.getToken = getToken
        }
    }
    async request(request) {
        if (typeof this.getToken === 'function') {
            const token = await this.getToken()

            request.headers.append('Authorization', 'Bearer ' + token)
        }

        return request
    }
}
