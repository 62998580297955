import { isDefined } from './function'
import { isNotEmpty } from './string'
import { convertToURLSearchParams } from './params'

// TODO Should be moved to a "path" module and function rename to "join"
export function buildPath(...paths) {
    return paths.flat(7).filter(isDefined).filter(isNotEmpty).join('/') // TODO write tests for me
}

export function appendParams(path, params = new URLSearchParams()) {
    if (!params) {
        return path
    }

    const search = convertToURLSearchParams(params)

    return [path, search.toString() || null].filter(isDefined).join('?')
}

export function isExternal(path) {
    try {
        const url = new URL(path)

        return !isNotExternalRegExp.test(url.host)
    } catch {
        return false
    }
}

const isNotExternalRegExp = new RegExp('avalanche.ca')
