import { useCallback } from 'react'

import { create } from 'zustand'
import { DANGER_RATINGS_MODAL_OPENED } from '@avcan/constants/products/mixpanel'

import { useForecast } from 'layouts/products/forecast/Context'
import { useSendTrackEvent } from 'hooks/useSendTrackEvent'

interface DangerRatingModalStore {
    isOpen: boolean
    open: () => void
    close: () => void
}

export const useDangerRatingModalStore = create<DangerRatingModalStore>(set => ({
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
}))

export const useOpenDangerRatingModal = () => {
    const { open } = useDangerRatingModalStore()
    const sendTrackEvent = useSendTrackEvent()
    const forecast = useForecast()

    return useCallback(() => {
        sendTrackEvent(DANGER_RATINGS_MODAL_OPENED, {
            forecastId: forecast?.id,
            forecast,
        })
        open()
    }, [sendTrackEvent, open, forecast])
}
