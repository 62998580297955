import Client from '../../lib/fetch/Client'
import * as Utils from '../../url'

export default class StaticClient {
    constructor(base) {
        const url = Utils.buildPath(base, 'static')

        this.client = new Client(url)
    }
    getSponsors(date = new Date()) {
        return this.client.get(['sponsors', date.toISOString().substr(0, 10)])
    }
}
