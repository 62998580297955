import { identity } from './function'

export function tail(array) {
    if (Array.isArray(array)) {
        return array[array.length - 1]
    }
}

export function head(array) {
    if (Array.isArray(array)) {
        return array[0]
    }
}

export function range(start = 0, end, step = 1) {
    const length = end === undefined ? start : Math.ceil((end - start) / step) + 1
    const first = end === undefined ? 0 : start

    if (length < 1) {
        return []
    }

    return Array(length)
        .fill(first)
        .map((value, index) => value + index * step)
}

export function groupBy(items = [], getKey = identity, keySorter = sorter) {
    const keys = new Set(items.map(getKey))

    return new Map(
        Array.from(keys)
            .sort(keySorter)
            .map(key => [key, items.filter(item => key === getKey(item))])
    )
}

export function filter(items = [], filters = []) {
    return filters.reduce((items, filter) => items.filter(filter), items)
}

function sorter(a, b) {
    return a.localeCompare(b)
}
