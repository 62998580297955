import fetch from './'
import * as Interceptors from './interceptors'
import * as URLUtils from '../../url'

export default class Client {
    constructor(base, ...interceptors) {
        this.base = base
        this.interceptors = new Interceptors.Composite(interceptors)
    }
    get(path, getToken) {
        return this.send('GET', path, undefined, getToken)
    }
    post(path, data, getToken) {
        return this.send('POST', path, data, getToken)
    }
    patch(path, data, getToken, config) {
        return this.send('PATCH', path, data, getToken, config)
    }
    put(path, data, getToken) {
        return this.send('PUT', path, data, getToken)
    }
    delete(path, getToken) {
        return this.send('DELETE', path, undefined, getToken)
    }
    send(method, path, data, getToken, config = {}) {
        const interceptor = new Interceptors.Token(getToken)
        const interceptors = this.interceptors.append(interceptor)

        config = { ...config, method }

        if (data) {
            config.body = JSON.stringify(data)
        }

        const url = this.buildURL(path)
        const request = new Request(url, config)

        return fetch(request, interceptors)
    }
    buildURL(...path) {
        return URLUtils.buildPath(this.base, path)
    }
}
