import Client, { Predicates } from '../'
import * as PredicatesUtils from './predicates'

// Generic getters
// TODO Should use the Prismic Tags API
export async function getTags(type) {
    const options = { fetch: 'document.tags' }
    const documents = await getAllDocuments(type, options)
    const tags = new Set(documents.map(document => document.tags).flat())

    return Array.from(tags).sort()
}

export async function getAllDocuments(type, options = {}) {
    const predicate = PredicatesUtils.type(type)
    const resultSets = []
    let page = 1
    let payload

    do {
        payload = await Client.query(predicate, { ...options, pageSize: MAX_PAGE_SIZE, page })

        resultSets.push(payload.results)
        page++
    } while (payload.next_page)

    return resultSets.flat()
}

export async function getEvery(type, uids, options = {}) {
    uids = Array.from(uids)

    if (uids.length > MAX_PAGE_SIZE) {
        throw new Error('Max of 100 UIDs at a time')
    }

    // Actually that value depends on the "locale", more locales more documents could be returned!
    options = { ...options, pageSize: MAX_PAGE_SIZE }

    const field = PredicatesUtils.my(type, 'uid')
    const predicate = Predicates.in(field, uids)
    const documents = await Client.query(predicate, options)

    return documents.results
}

export function formatToDateString(date) {
    const offset = date.getTimezoneOffset()
    const newDate = new Date(date.getTime() - offset * 60 * 1000)

    return newDate.toISOString().split('T')[0]
}

// Constants
const MAX_PAGE_SIZE = 100
