import Interceptor from './Interceptor'

export default class CompositeInterceptor extends Interceptor {
    constructor(...interceptors) {
        super()

        this.interceptors = interceptors.flat()
    }
    async request(request) {
        for (const interceptor of this.interceptors) {
            request = await interceptor.request(request)
        }

        return request
    }
    async response(response) {
        for (const interceptor of this.interceptors) {
            response = await interceptor.response(response)
        }

        return response
    }
    append(interceptor) {
        return new CompositeInterceptor(this.interceptors.concat(interceptor))
    }
}
