import Interceptor from './Interceptor'
import * as Urls from '../../url'

// TODO Does not work all the time, need to be removed!
export default class Prepend extends Interceptor {
    constructor(root) {
        super()

        this.root = root
    }
    request(request) {
        const { pathname, search } = new URL(request.url)
        const url = Urls.buildPath(this.root, ...pathname.split('/'), search)

        return new Request(url, request)
    }
}
