import { ReactNode } from 'react'
import clsx from 'clsx'

import { Modal, Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { FormattedMessage } from 'react-intl'

import COLOR_PALETTE, { COLOUR_BLIND_PALETTE } from '@avcan/constants/products/forecast/ratings/colors'
import { NO_RATING } from '@avcan/constants/products/forecast/ratings'

import { useUserSettings } from 'contexts/usersettings'
import { LikehoodOfAvalanche, TravelAdvices, SizeAndDistribution } from 'constants/products/forecast/messages'
import { IconByRating } from 'components/graphics/danger/Banner'
import { DangerRating, useTableDangerRatings } from './useDangerRatings'

import styles from './DangerRatingTable.module.css'

type DangerRatingTableProps = {
    show: boolean
    close: () => void
}

export const DangerRatingTable = ({ show, close }: DangerRatingTableProps) => {
    const rows = useTableDangerRatings()

    return (
        <Modal open={show} onClose={close}>
            <Box className={styles.Wrapper}>
                <div className={styles.Header}>
                    <h3 className={styles.Title}>
                        <FormattedMessage
                            defaultMessage="North American Public Avalanche Danger Scale"
                            description="Danger Ratings Table"
                        />
                    </h3>
                    <div className={styles.CloseButton}>
                        <IconButton onClick={close} aria-label="close">
                            <Close />
                        </IconButton>
                    </div>
                    <p className={styles.Subheader}>
                        <FormattedMessage
                            defaultMessage="Avalanche danger is determined by the likelihood, size, and distribution of avalanches. Safe backcountry travel requires training and experience. You control your risk by choosing when, where, and how you travel."
                            description="Danger Ratings Table"
                        />
                    </p>
                </div>
                <div className={styles.GridContainer}>
                    <div className={styles.Grid}>
                        <div className={styles.GridHeader}>
                            <div className={styles.HeaderCell}>
                                <FormattedMessage defaultMessage="Danger Level" description="Danger Ratings Table" />
                            </div>
                            <div className={styles.HeaderCell}>
                                <FormattedMessage defaultMessage="Travel Advice" description="Danger Ratings Table" />
                            </div>
                            <div className={styles.HeaderCell}>
                                <FormattedMessage defaultMessage="Likelihood" description="Danger Ratings Table" />
                            </div>
                            <div className={styles.HeaderCell}>
                                <FormattedMessage
                                    defaultMessage="Size and Distribution"
                                    description="Danger Ratings Table"
                                />
                            </div>
                        </div>
                        <div className={styles.GridBody}>
                            {rows.map(row => row.render && <FlexRow key={row.rating} row={row} />)}
                        </div>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}

type RowProps = {
    row: DangerRating
}

const FlexRow = ({ row }: RowProps) => {
    const hasLikelihood = row.rating in LikehoodOfAvalanche
    const hasSizeAndDistribution = row.rating in SizeAndDistribution
    const { colourblindModeEnabled } = useUserSettings()
    const palette = colourblindModeEnabled ? COLOUR_BLIND_PALETTE : COLOR_PALETTE
    const Icon = IconByRating.get(row.rating)

    return (
        <div className={clsx(styles.Row, !hasLikelihood && !hasSizeAndDistribution && styles.SpanColumns)}>
            <div
                className={styles.Swatch}
                style={{
                    backgroundColor: palette.get(row.rating),
                    border: row.rating === NO_RATING ? '1px solid #000' : 'none',
                }}
            ></div>
            <div className={styles.DangerLevelCell}>
                <h3>{row.heading}</h3>
                <svg width={80} height={55} viewBox="0 0 120 82">
                    {Icon && <Icon />}
                </svg>
            </div>
            <div className={clsx(styles.Cell, !hasLikelihood && !hasSizeAndDistribution && styles.SpanColumns)}>
                {TravelAdvices[row.rating] && (
                    <div className={styles.CellContent}>
                        <FormattedMessage
                            {...TravelAdvices[row.rating as keyof typeof TravelAdvices]}
                            values={
                                {
                                    b: (chunks: ReactNode) => <strong>{chunks}</strong>,
                                } as unknown as Record<string, ReactNode>
                            }
                        />
                    </div>
                )}
            </div>
            {hasLikelihood && (
                <div className={styles.Cell}>
                    <div className={styles.CellContent}>
                        <FormattedMessage {...LikehoodOfAvalanche[row.rating as keyof typeof LikehoodOfAvalanche]} />
                    </div>
                </div>
            )}
            {hasSizeAndDistribution && (
                <div className={styles.Cell}>
                    <div className={styles.CellContent}>
                        <FormattedMessage {...SizeAndDistribution[row.rating as keyof typeof SizeAndDistribution]} />
                    </div>
                </div>
            )}
        </div>
    )
}
