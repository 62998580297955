export const PARKS_CANADA = 'parks-canada'
export const PARKS_CANADA_BYK = 'parks-byk'
export const PARKS_CANADA_GLACIER = 'parks-glacier'
export const PARKS_CANADA_JASPER = 'parks-jasper'
export const PARKS_CANADA_WATERTON = 'parks-waterton'
export const KANANASKIS = 'kananaskis'
export const AVALANCHE_QUEBEC = 'avalanche-quebec'
export const AVALANCHE_CANADA = 'avalanche-canada'
export const VANCOUVER_ISLAND = 'vancouver-island'

export default AVALANCHE_CANADA
