import Interceptor from './interceptors/Interceptor'
import HTTPError from './HTTPError'

export default async function (request, interceptor = new Interceptor()) {
    request = await interceptor.request(request)
    function respond(response) {
        return interceptor.response(response)
    }

    return fetch(request).then(respond).then(consume)
}

// Utils
async function consume(response) {
    try {
        if (response.ok) {
            return response.json()
        } else {
            const text = await response.text()
            let payload

            try {
                payload = JSON.parse(text) // Returned payload is a JSON
            } catch {
                payload = text // Returned payload is a String
            }

            const error = new HTTPError(response, payload)

            return Promise.reject(error)
        }
    } catch (error) {
        return Promise.reject(error)
    }
}
