export default class HTTPError extends Error {
    constructor(response, payload) {
        super(payload?.message || response.statusText)
        this.name = 'HTTPError'
        this.status = response.status
        this.payload = payload
    }
}

export function isNotFound(error) {
    return statusOf(error, 404)
}

function statusOf(error, status) {
    return error instanceof HTTPError && error.status === status
}
