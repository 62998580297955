import Interceptor from './Interceptor'

export default class APIKeyInterceptor extends Interceptor {
    constructor(key) {
        super()

        this.key = key
    }
    request(request) {
        request.headers.append('x-api-key', this.key)

        return request
    }
}
