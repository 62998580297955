import Interceptor from './Interceptor'

export default class CacheKeyInterceptor extends Interceptor {
    request(request) {
        this.cache = new Map()

        const key = createKey(request)

        if (this.cache.has(key)) {
            const value = this.cache.get(key)

            request.headers.append(HEADER_NAME, value)
        }

        return request
    }
    response(response) {
        if (response.headers.has(HEADER_NAME)) {
            const key = createKey(response)
            const value = response.headers.get(HEADER_NAME)

            this.cache.set(key, value)
        }

        return response
    }
}

// Utils & constants
const HEADER_NAME = 'x-cache-key'
function createKey(object) {
    const url = new URL(object.url)

    url.searchParams.delete('page')

    return url.toString()
}
