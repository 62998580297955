import PropTypes from 'prop-types'

import Elevations, { ALP, TLN, BTL } from '@avcan/constants/products/forecast/elevations'
import {
    NO_RATING,
    LOW,
    MODERATE,
    CONSIDERABLE,
    HIGH,
    EXTREME,
    NO_FORECAST,
    SPRING,
    EARLY_SEASON,
    VALID_RATINGS,
} from '@avcan/constants/products/forecast/ratings'

import { BannerFill, BannerStroke, TextFill } from './colors'
import * as Icons from './Icons'

const ELEVATIONS_VALUES = new Map([
    [ALP, 0],
    [TLN, 1],
    [BTL, 2],
])

Banner.propTypes = {
    elevation: PropTypes.shape({
        value: PropTypes.oneOf(Array.from(Elevations)).isRequired,
        display: PropTypes.string.isRequired,
    }).isRequired,
    rating: PropTypes.shape({
        value: PropTypes.oneOf(VALID_RATINGS).isRequired,
        display: PropTypes.string.isRequired,
        displayFull: PropTypes.string.isRequired,
    }).isRequired,
}

export default function Banner({ elevation, rating }) {
    const step = ELEVATIONS_VALUES.get(elevation.value)
    const dx = 255 + 130 + step * 20
    const dy = 205 + 6 + step * 50
    const width = 301 - step * 20
    const { value, display, displayFull } = rating
    const fill = BannerFill.get(value)
    const stroke = BannerStroke.get(value)
    const textFill = TextFill.get(value)
    const Icon = IconByRating.get(value)

    return (
        <g transform={`translate(${dx} ${dy})`}>
            <rect
                x={18}
                width={width}
                height={37}
                fill={fill}
                stroke={stroke}
                strokeWidth={0.5}
                strokeMiterlimit={10}
            />
            <text x={70} y={23} fill={textFill} tabIndex="0" fontSize={18.5} fontWeight="bold">
                <title>{displayFull}</title>
                {display}
            </text>
            <g transform="scale(0.45)">
                <Icon />
            </g>
        </g>
    )
}

export const IconByRating = new Map([
    [LOW, Icons.Low],
    [MODERATE, Icons.Moderate],
    [CONSIDERABLE, Icons.Considerable],
    [HIGH, Icons.High],
    [EXTREME, Icons.Extreme],
    [NO_RATING, Icons.NoRating],
    [NO_FORECAST, Icons.NoForecast],
    [SPRING, Icons.Spring],
    [EARLY_SEASON, Icons.EarlySeason],
])
