export function noop() {}

export function identity(value) {
    return value
}

export function constant(value) {
    return () => value
}

export function isNotDefined(value) {
    return value == null
}

export function isDefined(value) {
    return value != null
}

export function truth() {
    return true
}

export function falsy() {
    return false
}

export function memoize(func) {
    return value => {
        if (!CACHE.has(value)) {
            CACHE.set(value, func(value))
        }

        return CACHE.get(value)
    }
}

const CACHE = new Map()
