import { truth, isDefined, identity } from './function'
import { tail } from './array'

export function get(object, path = [], defaultValue) {
    if (!Array.isArray(path)) {
        throw new TypeError('"path" should be an Array')
    }

    for (const key of path) {
        if (isObject(object)) {
            object = object[key]
        } else {
            return defaultValue
        }
    }

    return object || defaultValue
}

export function set(object, path = [], value) {
    if (!isObject(object)) {
        throw new TypeError('"object" should be an Object')
    }

    if (!Array.isArray(path)) {
        throw new TypeError('"path" should be an Array')
    }

    if (path.length === 0) {
        Object.assign(object, value)

        return object
    }

    const secondLast = path.slice(0, -1).reduce((object, key, index) => {
        if (!isObject(object[key])) {
            object[key] = typeof path[index + 1] === 'number' ? [] : {}
        }

        return object[key]
    }, object)

    secondLast[tail(path)] = value

    return object
}

export function isObject(object) {
    return object != null && typeof object === 'object'
}

export function clean(object = {}, filter = truth) {
    return Object.fromEntries(
        Object.entries(object)
            .filter(([_, value]) => isDefined(value))
            .filter(filter)
    )
}

export function omit(object = {}, ...keys) {
    keys = keys.flat()

    return Object.fromEntries(Object.entries(object).filter(([name]) => !keys.includes(name)))
}

export function pick(object = {}, ...keys) {
    keys = keys.flat()

    return Object.fromEntries(Object.entries(object).filter(([name]) => keys.includes(name)))
}

export function isEmpty(object) {
    if (!isObject(object)) {
        return true
    }

    return Object.keys(object).length === 0
}

export function transform(object, transformKey = identity, transformValue = identity) {
    if (!isObject(object)) {
        return object
    }

    function transformEntry([key, value]) {
        return [transformKey(key), transformValue(value, key)]
    }

    return Object.fromEntries(Object.entries(object).map(transformEntry))
}
