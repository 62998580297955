export const LOW = 'low'
export const MODERATE = 'moderate'
export const CONSIDERABLE = 'considerable'
export const HIGH = 'high'
export const EXTREME = 'extreme'
export const NO_RATING = 'norating'
export const NO_FORECAST = 'noforecast'
export const SPRING = 'spring'
export const EARLY_SEASON = 'earlyseason'

// It is an Array, order is important, so please do not change! Order is preserved in the Set below
const LEVELS = [NO_RATING, LOW, MODERATE, CONSIDERABLE, HIGH, EXTREME]

export const VALID_RATINGS = [
    NO_FORECAST,
    EARLY_SEASON,
    SPRING,
    NO_RATING,
    LOW,
    MODERATE,
    CONSIDERABLE,
    HIGH,
    EXTREME,
]

export default new Set(LEVELS)
