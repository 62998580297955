export const FORECAST = 'forecast'
export const OFFSEASON = 'offseason'
export const MOUNTAIN_INFORMATION_NETWORK = 'mountain-information-network'
export const MOUNTAIN_CONDITIONS_REPORT = 'mountain-conditions-report'
export const ACCIDENT = 'accident'
export const WEATHER_STATION = 'weather-station'
export const SPAW = 'spaw'
export const CLOSURE_ZONE = 'closure-zone'
export const ICE_CLIMB = 'ice-climb'

export const KindOfForecast = new Set([FORECAST, OFFSEASON])
