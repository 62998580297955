import { useIntl } from 'react-intl'
import { isWithinRange } from 'date-fns'

import {
    EARLY_SEASON,
    SPRING,
    NO_RATING,
    LOW,
    MODERATE,
    CONSIDERABLE,
    HIGH,
    EXTREME,
} from '@avcan/constants/products/forecast/ratings'
import { TravelAdvices } from 'constants/products/forecast/messages'

type Rating = keyof typeof TravelAdvices

export type DangerRating = {
    rating: Rating
    heading: React.ReactNode
    render: boolean
}

export const useLegendDangerRatings = (): DangerRating[] => {
    const intl = useIntl()
    const today = new Date()
    const currentYear = today.getFullYear()

    return [
        {
            rating: LOW,
            heading: intl.formatMessage(
                {
                    defaultMessage: '1{br}Low',
                    description: 'Map legend',
                },
                { br: <br /> }
            ),
            render: true,
        },
        {
            rating: MODERATE,
            heading: intl.formatMessage(
                {
                    defaultMessage: '2{br}Mod',
                    description: 'Map legend',
                },
                { br: <br /> }
            ),
            render: true,
        },
        {
            rating: CONSIDERABLE,
            heading: intl.formatMessage(
                {
                    defaultMessage: '3{br}Con',
                    description: 'Map legend',
                },
                { br: <br /> }
            ),
            render: true,
        },
        {
            rating: HIGH,
            heading: intl.formatMessage(
                {
                    defaultMessage: '4{br}High',
                    description: 'Map legend',
                },
                { br: <br /> }
            ),
            render: true,
        },
        {
            rating: EXTREME,
            heading: intl.formatMessage(
                {
                    defaultMessage: '5{br}Extreme',
                    description: 'Map legend',
                },
                { br: <br /> }
            ),
            render: true,
        },
        {
            rating: NO_RATING,
            heading: intl.formatMessage(
                {
                    defaultMessage: 'No{br}Rating',
                    description: 'Map legend',
                },
                { br: <br /> }
            ),
            render: isWithinRange(
                today,
                new Date(currentYear, 5, 15), // June 15th
                new Date(currentYear, 8, 30) // September 30th
            ),
        },
        {
            rating: EARLY_SEASON,
            heading: intl.formatMessage(
                {
                    defaultMessage: 'Early{br}Season',
                    description: 'Map legend',
                },
                { br: <br /> }
            ),
            render: isWithinRange(
                today,
                new Date(currentYear, 9, 1), // October 1st
                new Date(currentYear, 10, 20) // November 20th
            ),
        },
        {
            rating: SPRING,
            heading: intl.formatMessage({
                defaultMessage: 'Spring',
                description: 'Map legend',
            }),
            render: isWithinRange(
                today,
                new Date(currentYear, 3, 1), // April 1st (months are 0-indexed)
                new Date(currentYear, 5, 15) // June 15th
            ),
        },
    ]
}

export const useTableDangerRatings = (): DangerRating[] => {
    const intl = useIntl()

    return [
        {
            rating: EXTREME,
            heading: intl.formatMessage({
                defaultMessage: '5 - Extreme',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: HIGH,
            heading: intl.formatMessage({
                defaultMessage: '4 - High',
                description: 'Map legend',
            }),
            render: true,
        },

        {
            rating: CONSIDERABLE,
            heading: intl.formatMessage({
                defaultMessage: '3 - Considerable',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: MODERATE,
            heading: intl.formatMessage({
                defaultMessage: '2 - Moderate',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: LOW,
            heading: intl.formatMessage({
                defaultMessage: '1 - Low',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: NO_RATING,
            heading: intl.formatMessage({
                defaultMessage: 'No Rating',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: EARLY_SEASON,
            heading: intl.formatMessage({
                defaultMessage: 'Early Season',
                description: 'Map legend',
            }),
            render: true,
        },
        {
            rating: SPRING,
            heading: intl.formatMessage({
                defaultMessage: 'Spring',
                description: 'Map legend',
            }),
            render: true,
        },
    ]
}
