export const EN = 'en' // en-CA
export const FR = 'fr' // fr-CA

export default EN

export const LOCALES = new Set([EN, FR])

export const TEXTS = new Map([
    [EN, 'English'],
    [FR, 'Français'],
])
