export function round(number, precision = 0) {
    const decimals = Math.pow(10, precision)

    return Math.round(number * decimals) / decimals
}

export function isValid(number) {
    return typeof number === 'number' && !isNaN(number)
}

export function isNotValid(number) {
    return typeof number !== 'number' || isNaN(number)
}

export function fromString(string) {
    switch (typeof string) {
        case 'string':
            if (string !== '') {
                return Number(string)
            }
        case 'number':
            return string
        default:
            return NaN
    }
}
